<template>
  <div>
    <div class="app-header1 header py-1 d-flex">
      <div class="container-fluid">
        <div class="d-flex ">
          <router-link
            class="header-brand"
            to="/"
          >
            <img
              alt="logo"
              class="header-brand-img"
              src="@/assets/images/logo.png"
            >
          </router-link>
          <div
            v-if="isAuthenticated && user.isFileRequest"
            class="d-flex align-items-center justify-content-between"
          >
            <router-link
              class="company-nav-tab px-4"
              :to="`/${$t('routes.files')}`"
            >
              {{ $t('labels.files') }}
            </router-link>
            <router-link
              class="company-nav-tab px-4"
              :to="`/${$t('routes.fileRequests')}`"
            >
              {{ $t('labels.fileRequests') }}
            </router-link>
          </div>
          <div
            v-if="isAuthenticated"
            class="d-flex order-lg-2 ml-auto"
          >
            <div class="dropdown d-none d-md-flex">
              <a class="nav-link icon full-screen-link">
                <i
                  id="fullscreen-button"
                  class="fe fe-maximize-2"
                />
              </a>
            </div>
            <div class="dropdown">
              <a
                class="nav-link pr-0 mt-3 "
                data-toggle="dropdown"
                href="#"
              >
                {{ user.companyName }}
                <i class="fa fa-angle-down ml-2" />
              </a>
              <div
                class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
              >
                <a
                  class="dropdown-item"
                  @click="onLogout"
                >
                  <i class="dropdown-icon icon icon-power" />
                  {{ $t('labels.logout') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      selectedMenuItemName: null
    }
  },
  computed: { ...mapGetters(['user', 'isAuthenticated']) },
  methods: {
    onLogout () {
      this.$store.dispatch('logout')
    },
    hasActiveChild (childNames) {
      return childNames.includes(this.$route.name)
    },
    isMenuItemActive (menuItemName, hasChild = false) {
      let hasSelectedChildItem = false

      if (hasChild) {
        const filteredMenuItems = this.menuItems.filter(menuItem => (
          menuItemName === menuItem.name &&
            menuItem.childNames.includes(this.$route.name)
        ))

        hasSelectedChildItem = filteredMenuItems.length > 0
      }

      if (
        this.$route.name !== menuItemName &&
        !hasSelectedChildItem &&
        this.$route.meta.parentRoute === menuItemName
      ) {
        hasSelectedChildItem = true
      }

      return this.$route.name === menuItemName || hasSelectedChildItem
    }
  }
}
</script>

<style>
.company-nav-tab {
  font-size: 1rem;
}

.company-nav-tab.router-link-active {
  font-weight: bold;
  text-decoration: underline;
}
</style>
